import { FileModel } from '../file';
import { RightsStatusType } from '../sections';
import { ChecklistTaskType } from './checklist-task-type';

export interface ChecklistTask<TChecklistDefaultValue = unknown> {
  id: string;
  name: string;
  description: string;
  type: ChecklistTaskType;
  mandatory: boolean;
  useConditionals: boolean;
  defaultValue: TChecklistDefaultValue;
  imagesRights: RightsStatusType;
  filesRights: RightsStatusType;
  commentsRights: RightsStatusType;
  files: FileModel[];
  link: string;
  notes: string;
}

export interface ChecklistSection {
  id: string;
  name: string;
  description: string;
  entries: ChecklistTask[];
}
